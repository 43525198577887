import { ArrowTopRightOnSquareIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import SafariBrowserLogo from "src/assets/images/safari_browser_logo.png";

export default function BrowserEmbedError({ browser }) {
  return (
    <div className="flex items-center justify-center gap-x-5">
      <div className="flex w-full items-center gap-x-4 rounded bg-white py-4 pl-4 pr-6">
        {browser === "Safari" ? (
          <img
            className="h-20 w-20 opacity-70"
            alt="safari browser"
            src={SafariBrowserLogo}
          />
        ) : (
          <>
            <ExclamationCircleIcon className="h-20 w-20 stroke-blue-500 stroke-2" />
          </>
        )}
        <div className="flex flex-col">
          {/* <p>{`Third-party cookies in ${browser}: ${enabled ? "Enabled" : "Disabled"}`}</p> */}
          {browser === "Safari" ? (
            <>
              <p className="flex items-center text-2xl font-extrabold text-gray-800">You’re using Safari...</p>
              {/* <p className="flex items-center text-gray-900">
                You’ll need to
                <a
                  className="flex items-center gap-x-1 pl-1 text-blue-500 underline transition-all hover:text-blue-700"
                  href="https://support.apple.com/en-tj/guide/safari/sfri40732/mac">
                  {" "}
                  allow cross-site tracking
                </a>
              </p> */}
              <p className="flex items-center font-thin text-gray-400">
                The prefered browser is{" "}
                <a
                  className="flex items-center gap-x-1 pl-1 text-blue-500 underline transition-all hover:text-blue-700"
                  href="https://www.google.com/chrome/dr/download/?brand=JSBI&ds_kid=43700079939272283&gad_source=1&gclid=CjwKCAjww_iwBhApEiwAuG6ccBnV6vchH7_R4EBF6qEjcHJX_12SJ4Zeh25PI3F5o4m2qNaGaSnJ6RoCiXMQAvD_BwE&gclsrc=aw.ds">
                  Google Chrome <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </a>
              </p>
            </>
          ) : (
            <>
              <p className="flex items-center text-2xl font-extrabold text-gray-800">You’ve disabled 3rd-Party Cookies</p>
              <p className="flex items-center text-gray-900">
                You’ll need to
                <a
                  className="flex items-center gap-x-1 px-1 text-blue-500 underline transition-all hover:text-blue-700"
                  href={browser === "Chrome" ? "https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop" : "https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52"}>
                  {" "}
                  allow cross-site tracking
                </a>{" "}
                in {browser}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
